import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { fetchCurrentPageContent } from "../../session/reducers/fetchCurrentPageContent";
import { PageContent } from "../dataTypes";
import { pageContentAdapter } from "../pageContentAdapter";

export const addFetchCurrentPageContentReducers = (builder: EntityActionReducerBuilderInterface<PageContent>) => {
  builder.addCase(fetchCurrentPageContent.fulfilled, (state, action) => {
    const { payload } = action;

    pageContentAdapter.upsertOne(state, {
      ...payload,
      fetchedTimestamp: Date.now(),
      requestState: REQUEST_STATE.FULFILLED,
    });
  });
};
