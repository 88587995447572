import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { userAccessMessageSliceName } from "./dataTypes";
import { userAccessMessageAdapter } from "./userAccessMessageAdapter";

const selectSliceState = (state: RootState) => state[appDataName][userAccessMessageSliceName];

const adapterSelectors = userAccessMessageAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectUserAccessMessageEntities = adapterSelectors.selectEntities;
export const selectUserAccessMessages = adapterSelectors.selectAll;
export const selectUserAccessMessage = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
