import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { formDefinitionSliceName } from "./dataTypes";
import { formDefinitionAdapter } from "./formDefinitionAdapter";
import { addFetchFormDefinitionReducers } from "./reducers/fetchFormDefinition";

const slice = createSlice({
  name: formDefinitionSliceName,
  initialState: formDefinitionAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchFormDefinitionReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
