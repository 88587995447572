import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectIsSingleSignOn } from "../sessionSelectors";
import { setIsSingleSignOn } from "../sessionSlice";
import useFetchSingleSignOnUserData from "./useFetchSingleSignOnUserData";

let fetching: boolean;

const useIsSingleSignOn = () => {
  const dispatch = useAppDispatch();
  const isSingleSignOn = useAppSelector(selectIsSingleSignOn);
  const { loadUserData } = useFetchSingleSignOnUserData();

  const getSSOUserData = useCallback(async () => {
    if (!fetching) {
      fetching = true;

      const response = await loadUserData();

      dispatch(setIsSingleSignOn(!response.isError));
      fetching = false;
    }
  }, [dispatch, loadUserData]);

  useEffect(() => {
    if (isSingleSignOn === undefined) getSSOUserData();
  }, [getSSOUserData, isSingleSignOn]);

  return isSingleSignOn;
};

export default useIsSingleSignOn;
