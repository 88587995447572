import { AppDispatch } from "../../../app/store";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import {
  ResponseUserAccessMessage,
  UpdateUserAccessMessageRequest,
  UserAccessMessage,
  userAccessMessageSliceName,
} from "../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import { userAccessMessageAdapter } from "../userAccessMessageAdapter";
import { api } from "../../../config/settings";
import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import defaultRestOptions from "../../utils/defaultRestOptions";

async function sendUpdateAccessMessage(dispatch: AppDispatch, id: string, data: UpdateUserAccessMessageRequest) {
  const url = api.user.accessMessage(id);
  const request = new Request(url, Method.PUT);

  return await sendRequest(request, data, await defaultRestOptions({ dispatch }));
}

export const updateUserAccessMessage = createAsyncThunk<
  ResponseUserAccessMessage | null,
  { id: string; request: UpdateUserAccessMessageRequest }
>(userAccessMessageSliceName + "/updateUserAccessMessage", (dispatch, { id, request }) =>
  sendUpdateAccessMessage(dispatch, id, request),
);

export const addUpdateUserAccessMessageReducers = (builder: EntityActionReducerBuilderInterface<UserAccessMessage>) => {
  builder.addCase(updateUserAccessMessage.pending, (state, action) => {
    const id = action.meta.arg.id;
    const userAccessMessage = state.entities[id] ?? ({ id } as UserAccessMessage);

    userAccessMessageAdapter.upsertOne(state, {
      ...userAccessMessage,
      requestState: REQUEST_STATE.PENDING,
      errorMessage: undefined,
    });
  });
  builder.addCase(updateUserAccessMessage.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { id },
      },
    } = action;

    userAccessMessageAdapter.updateOne(state, {
      id,
      changes: { requestState: REQUEST_STATE.FULFILLED, errorMessage: undefined },
    });

    if (payload) {
      userAccessMessageAdapter.upsertOne(state, {
        ...payload,
        fetchedTimestamp: Date.now(),
        requestState: REQUEST_STATE.FULFILLED,
        errorMessage: undefined,
      });
    }
  });
  builder.addCase(updateUserAccessMessage.rejected, (state, action) => {
    const id = action.meta.arg.id;

    userAccessMessageAdapter.updateOne(state, {
      id,
      changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: action.payload?.message },
    });
  });
};
