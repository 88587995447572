import { FormData } from "@myloc/myg-forms";
import PropTypes from "prop-types";
import { useCallback, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { useAppSelector } from "../../../../app/hooks";
import { UploadFile } from "../../../../features/file/dataTypes";
import { UpdateFormRowRequest, UpdateFormSectionRequest } from "../../../../features/form/dataTypes";
import useGetAggregatedFormDefinition from "../../../../features/formDefinition/hooks/useGetAggregatedFormDefinition";
import { ServiceType } from "../../../../features/serviceType/dataTypes";
import { selectCurrentUser } from "../../../../features/session/sessionSelectors";
import { CUSTOMER_TYPE } from "../../../../features/webOrder/dataTypes";
import useCreateAndOrderWebOrder from "../../../../features/webOrder/hooks/useCreateAndOrderWebOrder";
import { PRIORITY, SUB_CONTENT_TYPE } from "../../../../features/webOrderContent/dataTypes";
import { useTranslate } from "../../../../language/i18n";
import { CONTENT_TYPE } from "../../../../utils/constants";
import pages from "../../../../utils/pages";
import Breadcrumbs from "../../../shared/Breadcrumbs/Breadcrumbs";
import Page from "../../../shared/Page/Page";
import LoadingSpinner from "../../../shared/Spinner/LoadingSpinner";
import styles from "./ServiceTypeInformation.module.scss";

const ServiceTypeInformation = ({
  serviceType,
  breadcrumbs,
}: {
  serviceType: ServiceType;
  breadcrumbs?: () => Parameters<typeof Breadcrumbs>["0"]["breadcrumbs"];
}) => {
  const translate = useTranslate();
  const history = useHistory();
  const user = useAppSelector(selectCurrentUser);
  const { formDefinition, isLoading, isFilesLoading } = useGetAggregatedFormDefinition({
    id: serviceType.formDefinitionId,
    loadFiles: true,
  });

  const { createAndOrderWebOrder, isSuccess } = useCreateAndOrderWebOrder();
  const isCreating = useRef(false);

  const onNext = useCallback(() => {
    history.push(pages.SERVICE_CONFIRMATION.PATH);
  }, [history]);

  useEffect(() => {
    if (isCreating.current && isSuccess) {
      isCreating.current = false;
      onNext();
    }
  }, [isSuccess, onNext]);

  if (isFilesLoading || isLoading || !user) {
    return <LoadingSpinner />;
  }

  const getUpdateFormSequenceRequest = (
    sequences: NonNullable<ReturnType<typeof useGetAggregatedFormDefinition>["formDefinition"]>["sequences"],
  ) => {
    const data: (UpdateFormSectionRequest | UpdateFormRowRequest)[] = [];

    for (const sequence of sequences) {
      if ("type" in sequence) {
        data.push({ id: sequence.id, value: sequence.value !== "" ? sequence.value : null });
      } else if (sequence.sequences) {
        data.push({ id: sequence.id, sequences: getUpdateFormSequenceRequest(sequence.sequences) });
      }
    }

    return data;
  };

  const handleOnSubmit = async (
    form: ReturnType<typeof useGetAggregatedFormDefinition>["formDefinition"],
    files: UploadFile[] | undefined,
  ) => {
    if (form?.definitionVersionId) {
      const payload = {
        customer: {
          id: user.id,
          type: CUSTOMER_TYPE.PERSON,
        },
        contents: [
          {
            contentType: CONTENT_TYPE.SERVICE_TYPE,
            subContentType: SUB_CONTENT_TYPE.NONE,
            form: {
              definitionVersionId: form.definitionVersionId,
              sequences: getUpdateFormSequenceRequest(form.sequences),
            },
            lines: [
              {
                serviceTypeId: serviceType.id,
              },
            ],
            priority: PRIORITY.NORMAL,
          },
        ],
        files: files,
      };

      isCreating.current = true;
      await createAndOrderWebOrder(payload);
    }
  };

  return (
    <Page title={serviceType.name} breadcrumbs={breadcrumbs?.()} customCssClass={styles.productDetail} spaceBetween>
      <div className={styles.layout}>
        <section className={styles.formDetails}>
          {formDefinition ? (
            <FormData form={formDefinition} onSubmit={handleOnSubmit} inputMode enableFileUpload />
          ) : (
            <p>{translate("NO_FORM_DATA")}</p>
          )}
        </section>
        {serviceType.externalNote && (
          <section>
            <section className={styles.description}>
              <p dangerouslySetInnerHTML={{ __html: serviceType.externalNote }}></p>
            </section>
          </section>
        )}
      </div>
    </Page>
  );
};

ServiceTypeInformation.propTypes = {
  serviceType: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.func,
};

export default ServiceTypeInformation;
