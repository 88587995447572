import { AdditionalRequestState, FetchedIdObject, IdObject } from "../dataTypes";

export const contactInformationSliceName = "contactInformation";

export interface ResponseContactInformation extends IdObject {
  firstName: string;
  surname: string;
  cellPhoneNumber: string;
  emailAddress: string;
}

export interface ContactInformation extends ResponseContactInformation, AdditionalRequestState, FetchedIdObject {}
