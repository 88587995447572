import { AdditionalRequestState, FetchedIdObject } from "../dataTypes";
import { ResponseForm } from "../form/dataTypes";

export const formDefinitionSliceName = "formDefinition";

export interface ResponseFormDefinition extends ResponseForm {
  definitionVersionId?: string;
}

export interface FormDefinition extends ResponseFormDefinition, AdditionalRequestState, FetchedIdObject {}
