import { ValueOf } from "../../utils/dataTypes";
import {
  AdditionalRequestState,
  DATA_TYPE,
  FetchedIdObject,
  IdLabel,
  IdObject,
  LabelValue,
  PRESENTATION,
} from "../dataTypes";

export const formSliceName = "form";

export interface FormSequence extends IdLabel {
  description?: string;
  sequenceNumber: number;
}

export interface FormRow extends FormSequence {
  type: ValueOf<typeof DATA_TYPE> | null;
  fromValue?: any;
  toValue?: any;
  value: any | null;
  isConstantValue?: boolean;
  constants?: LabelValue[];
  isValueMandatory?: boolean;
  presentation?: ValueOf<typeof PRESENTATION>;
  fileId?: string;
}

export interface FormSection extends FormSequence {
  sequences?: (FormSection | FormRow)[];
}

export interface ResponseForm extends IdLabel {
  description?: string;
  sequences: (FormSection | FormRow)[];
}

export interface Form extends ResponseForm, AdditionalRequestState, FetchedIdObject {}

export interface UpdateFormSectionRequest extends IdObject {
  sequences: (UpdateFormSectionRequest | UpdateFormRowRequest)[];
}

export interface UpdateFormRowRequest extends IdObject {
  value?: any;
}

export interface CreateFormRequest {
  definitionVersionId: string;
  sequences: (UpdateFormSectionRequest | UpdateFormRowRequest)[];
}
