import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { userSliceName } from "./dataTypes";
import { addFetchCurrentUserReducers } from "./reducers/fetchCurrentUser";
import { addUpdateCurrentUserDataReducers } from "./reducers/updateCurrentUserData";
import { userAdapter } from "./userAdapter";

const slice = createSlice({
  name: userSliceName,
  initialState: userAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchCurrentUserReducers(builder);
    addUpdateCurrentUserDataReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
