import { useCallback } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { useTranslate } from "../../../language/i18n";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { setError } from "../../dialog/dialogSlice";
import { updateCurrentUserData } from "../../session/reducers/updateCurrentUserData";
import { getValidationResult } from "../../utils/getValidationResults";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { UpdateUserCurrentDataRequest } from "../dataTypes";
import useLazyGetCurrentUser from "./useLazyGetCurrentUser";

const useUpdateCurrentUserData = () => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const result = useValidateUserIsLoggedIn();

  const { user, isUninitialized, isLoading, isSuccess, isError, errorMessage } = useLazyGetCurrentUser({
    loadIfNotInitialized: true,
  });

  const validateUpdateUserData = useCallback(
    (request?: Partial<UpdateUserCurrentDataRequest>) => {
      if (result.isError) return result;
      if (!request) return getValidationResult(true, translate("DATA_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.firstName)
        return getValidationResult(true, translate("FIRST_NAME_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.surname) return getValidationResult(true, translate("SURNAME_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.cellPhoneNumber)
        return getValidationResult(true, translate("PHONE_NUMBER_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.emailAddress)
        return getValidationResult(true, translate("EMAIL_MISSING"), HttpStatusCodes.BAD_REQUEST);

      return getValidationResult(false);
    },
    [translate, result],
  );

  const updateUserData = useCallback(
    async (request: UpdateUserCurrentDataRequest) => {
      const result = validateUpdateUserData(request);

      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        await dispatch(updateCurrentUserData(request));
      }
    },
    [dispatch, validateUpdateUserData],
  );

  return { user, isUninitialized, isLoading, isSuccess, isError, errorMessage, updateUserData };
};

export default useUpdateCurrentUserData;
