import { useCallback } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { useTranslate } from "../../../language/i18n";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { setError } from "../../dialog/dialogSlice";
import { getValidationResult } from "../../utils/getValidationResults";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { UpdateUserAccessMessageRequest } from "../dataTypes";
import { updateUserAccessMessage as updateUserAccessMessageAction } from "../reducers/updateUserAccessMessage";
import useLazyGetUserAccessMessage from "./useLazyGetUserAccessMessage";

const useUpdateUserAccessMessage = (id?: string) => {
  const dispatch = useAppDispatch();
  const translate = useTranslate();
  const result = useValidateUserIsLoggedIn();

  const {
    userAccessMessage,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
  } = useLazyGetUserAccessMessage({ id, loadIfNotInitialized: true });

  const validateUpdateUserAccessMessage = useCallback(
    (request?: Partial<UpdateUserAccessMessageRequest>) => {
      if (result.isError) return result;
      if (!request) return getValidationResult(true, translate("DATA_MISSING"), HttpStatusCodes.BAD_REQUEST);
      if (!request.accept) return getValidationResult(true, translate("NOT_ACCEPTED"), HttpStatusCodes.BAD_REQUEST);
      return getValidationResult(false);
    },
    [translate, result],
  );

  const updateUserAccessMessage = useCallback(
    async (request: UpdateUserAccessMessageRequest) => {
      const result = validateUpdateUserAccessMessage(request);

      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else if (id) {
        await dispatch(updateUserAccessMessageAction({ id, request }));
      }
    },
    [dispatch, id, validateUpdateUserAccessMessage],
  );

  return {
    userAccessMessage,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
    updateUserAccessMessage,
  };
};

export default useUpdateUserAccessMessage;
