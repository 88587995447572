import PropTypes from "prop-types";

export function formatPostalNo(postalNo: string) {
  return format(postalNo.replace(" ", ""));
}

function format(postalNo: string) {
  return postalNo.length === 5 ? postalNo.slice(0, 3) + " " + postalNo.slice(3, 5) : postalNo;
}

formatPostalNo.propTypes = {
  postalNo: PropTypes.string.isRequired,
};
