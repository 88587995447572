import { createSlice } from "@reduxjs/toolkit";
import { passwordPolicySliceName, PasswordPolicyState } from "./dataTypes";
import { addFetchPasswordPolicyReducers } from "./reducers/fetchPasswordPolicy";

const initialState: PasswordPolicyState = {};

const slice = createSlice({
  name: passwordPolicySliceName,
  initialState,
  reducers: {
    clearSlice() {
      return undefined;
    },
  },
  extraReducers: builder => {
    addFetchPasswordPolicyReducers(builder);
  },
});

const { actions, reducer } = slice;

export const { clearSlice } = actions;

export default reducer;
