import { useTranslate } from "../../language/i18n";
import { Box, Form, InputField, Button } from "@myloc/myloc-gui";
import Page from "../shared/Page/Page";
import pages from "../../utils/pages";
import styles from "./UserSettingsPage.module.scss";
import useLazyGetCurrentUser from "../../features/session/hooks/useLazyGetCurrentUser";
import React, { useEffect, useRef, useState } from "react";
import isEqual from "react-fast-compare";
import useUpdateCurrentUserData from "../../features/session/hooks/useUpdateCurrentUserData";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setInfo } from "../../features/dialog/dialogSlice";
import { selectIsSingleSignOn } from "../../features/session/sessionSelectors";

const UserSettingsPage = () => {
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const { user } = useLazyGetCurrentUser({ loadIfNotInitialized: true });
  const { updateUserData, isSuccess } = useUpdateCurrentUserData();
  const [userInfo, setUserInfo] = useState(user);
  const infoHasChanged = !user || !userInfo || !isEqual(user, userInfo);
  const isUpdating = useRef(false);
  const isSingleSignOn = useAppSelector(selectIsSingleSignOn);

  useEffect(() => {
    if (user) setUserInfo(user);
  }, [user]);

  useEffect(() => {
    if (isSuccess && isUpdating.current) {
      dispatch(setInfo({ value: translate("PROFILE_INFORMATION_UPDATED") }));
      isUpdating.current = false;
    }
  }, [isSuccess, isUpdating, dispatch, translate]);

  const handleOnSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    isUpdating.current = true;

    await updateUserData({
      firstName: userInfo?.firstName ?? "",
      surname: userInfo?.surname ?? "",
      cellPhoneNumber: userInfo?.cellPhoneNumber ?? "",
      emailAddress: userInfo?.emailAddress ?? "",
    });
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const field = e.target;

    if (userInfo) {
      setUserInfo({ ...userInfo, [field.name]: field.value });
    }
  };

  return (
    <Page customCssClass={styles.userPage} spaceBetween>
      <h2 className={styles.title}>{translate(pages.MY_PROFILE.NAME)}</h2>
      <Form className={styles.form} onSubmit={handleOnSubmit}>
        {isSingleSignOn ? (
          <>
            <Box title={translate("PERSONAL_DATA")}>
              <section className={styles.section}>
                <div>
                  <p className={styles.header}>{translate("FIRST_NAME")}</p>
                  <p className={styles.text}>{userInfo?.firstName ?? ""}</p>
                </div>
              </section>
              <section className={styles.section}>
                <div>
                  <p className={styles.header}>{translate("SURNAME")}</p>
                  <p className={styles.text}>{userInfo?.surname ?? ""}</p>
                </div>
              </section>
              <section className={styles.section}>
                <div>
                  <p className={styles.header}>{translate("EMAIL")}</p>
                  <p className={styles.text}>{userInfo?.emailAddress ?? ""}</p>
                </div>
              </section>
            </Box>
            <Box title={translate("PHONE_NO")}>
              <InputField
                label={translate("PHONE_NO")}
                name="cellPhoneNumber"
                customCssClass={styles.input}
                value={userInfo?.cellPhoneNumber ?? ""}
                onChange={handleFieldChange}
              />
              <Button type="submit" customCssClass={styles.btn} disabled={!infoHasChanged}>
                {translate("SAVE")}
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box title={translate("PERSONAL_DATA")}>
              <InputField
                label={translate("FIRST_NAME")}
                name="firstName"
                customCssClass={styles.input}
                value={userInfo?.firstName ?? ""}
                onChange={handleFieldChange}
              />
              <InputField
                label={translate("SURNAME")}
                name="surname"
                customCssClass={styles.input}
                value={userInfo?.surname ?? ""}
                onChange={handleFieldChange}
              />
              <InputField
                label={translate("PHONE_NO")}
                name="cellPhoneNumber"
                customCssClass={styles.input}
                value={userInfo?.cellPhoneNumber ?? ""}
                onChange={handleFieldChange}
              />
              <InputField
                label={translate("EMAIL")}
                name="emailAddress"
                type="email"
                customCssClass={styles.input}
                value={userInfo?.emailAddress ?? ""}
                onChange={handleFieldChange}
              />
            </Box>
            <Button type="submit" customCssClass={styles.btn} disabled={!infoHasChanged}>
              {translate("SAVE")}
            </Button>
          </>
        )}
      </Form>
    </Page>
  );
};

export default UserSettingsPage;
