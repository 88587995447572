import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import getFormData from "../../../utils/getFormData";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { CreateWebOrderRequest, CreateWebOrderResponse, WebOrder, webOrderSliceName } from "../dataTypes";
import { webOrderAdapter } from "../webOrderAdapter";

async function sendCreateAndOrderWebOrder(dispatch: AppDispatch, payload: CreateWebOrderRequest) {
  const { files, ...data } = payload;
  const url = api.webOrder.createAndOrderWebOrder();
  const request = new Request(url, Method.POST);

  return await sendRequest(request, getFormData({ data, files }), await defaultRestOptions({ dispatch }));
}

export const createAndOrderWebOrder = createAsyncThunk<CreateWebOrderResponse, CreateWebOrderRequest>(
  webOrderSliceName + "/createAndOrderWebOrder",
  (dispatch, request) => sendCreateAndOrderWebOrder(dispatch, request),
);

export const addCreateAndOrderWebOrderReducers = (builder: EntityActionReducerBuilderInterface<WebOrder>) => {
  builder.addCase(createAndOrderWebOrder.fulfilled, (state, action) => {
    webOrderAdapter.addOne(state, {
      ...action.payload.webOrder,
      fetchedTimestamp: Date.now(),
      requestState: REQUEST_STATE.FULFILLED,
    });
  });
};
