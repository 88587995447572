import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslate } from "../../../../../language/i18n";
import { MenuItem, SubMenuItem as SubMenuItemType } from "../../../../../utils/menu/dataTypes";
import { Button, THEME } from "@myloc/myloc-gui";
import styles from "../MenuDrawer.module.scss";

const SubMenuItem = ({ item, toggleMenu }: { item: SubMenuItemType; toggleMenu: (menuItem?: MenuItem) => void }) => {
  const translate = useTranslate();

  let content = <p>{translate(item.label)}</p>;

  if (item.link) content = <Link to={item.link}>{translate(item.label)}</Link>;

  if (item.action)
    content = (
      <Button theme={THEME.PLAIN} onClick={item.action}>
        {translate(item.label)}
      </Button>
    );

  return (
    <li className={styles.link} onClick={() => toggleMenu(item)}>
      {content}
    </li>
  );
};

SubMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default SubMenuItem;
