import PropType from "prop-types";
import { ReactNode } from "react";

import PageSize from "./PageSize";
import Pagination from "./Pagination";

import styles from "./Paginate.module.scss";
import usePagination from "../../../hooks/usePagination";
import { RootState } from "../../../app/rootReducer";
import { PaginationFilter } from "../../../features/utils/pagination";

const Paginate = <T extends PaginationFilter, R>({
  children,
  filter,
  loadOnMount,
  request,
  data,
  setData,
  countSelect,
  triggerData,
}: {
  children: ReactNode;
  filter: Readonly<T>;
  loadOnMount?: boolean;
  request?: (filter: T) => Promise<R[] | undefined>;
  data?: readonly Readonly<R>[];
  setData: (data: R[]) => void;
  countSelect: (state: RootState) => number;
  triggerData?: unknown;
}) => {
  const { settings, handlePageChange, handlePageSizeChange, page } = usePagination({
    request,
    loadOnMount,
    setData,
    filter,
    countSelect,
    triggerData,
  });

  return (
    <>
      {children}

      <div className={styles.paginationRow}>
        {data && data.length > 0 ? (
          <>
            <PageSize onChange={handlePageSizeChange} size={settings.amount} customCssClass={styles.pageSize} />
            <Pagination
              countSelect={countSelect}
              pageSize={settings.amount}
              currentPage={Math.ceil(settings.from / settings.amount) + 1}
              onPageChange={handlePageChange}
              customCssClass={styles.pagination}
              page={page}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

Paginate.propTypes = {
  children: PropType.node.isRequired,
  filter: PropType.object.isRequired,
  loadOnMount: PropType.bool,
  request: PropType.func,
  data: PropType.array,
  setData: PropType.func.isRequired,
  countSelect: PropType.func.isRequired,
  triggerData: PropType.any,
} as unknown;

export default Paginate;
