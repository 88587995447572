import { URL } from "@myloc/myloc-utils";

const endpoint = (url: string, params?: any, version = "/serv/v1") => {
  const root = () => {
    let domain;
    const host = window.location.host;

    if (process.env.NODE_ENV === "development" || host === process.env.REACT_APP_URL) {
      domain = process.env.REACT_APP_API_DOMAIN;
    } else {
      const protocol = window.location.protocol;

      domain = protocol + "//" + host;
    }

    const baseURI = (domain ?? "") + process.env.REACT_APP_API_PATH;

    return baseURI;
  };

  const templates = { ...params };

  const preparedUrl = url.replace(/{([^}]+)}/g, (_, key) => templates[key]);

  const baseURI = root();
  const fullPath = `${baseURI}${version}${preparedUrl}`;
  const uri = new URL(fullPath);

  return uri;
};

export const api = {
  account: {
    authentication: () => endpoint("/authentication"),
  },
  contactInformation: {
    contactInformation: (contactInformationId: string) =>
      endpoint("/contact-informations/{contactInformationId}", { contactInformationId }),
  },
  file: {
    file: (fileId: string) => endpoint("/files/{fileId}", { fileId }),
  },
  form: {
    form: (formId: string) => endpoint("/forms/{formId}", { formId }),
  },
  formDefinition: {
    formDefinition: (formDefinitionId: string) =>
      endpoint("/form-definitions/{formDefinitionId}", { formDefinitionId }),
  },
  pageContent: {
    currentContent: () => endpoint("/page-content"),
    files: (pageContentId: string) => endpoint("/page-contents/{pageContentId}/files", { pageContentId }),
    hyperlinks: (pageContentId: string) => endpoint("/page-contents/{pageContentId}/hyperlinks", { pageContentId }),
  },
  password: {
    password: () => endpoint("/password"),
    passwordRules: () => endpoint("/password/rules"),
  },
  resourceGroup: {
    resources: (resourceGroupId: string) => {
      return endpoint("/resource-groups/{resourceGroupId}/resources", { resourceGroupId });
    },
  },
  serviceType: {
    serviceTypes: () => endpoint("/service-types"),
    serviceType: (serviceTypeId: string) => endpoint("/service-types/{serviceTypeId}", { serviceTypeId }),
    files: (serviceTypeId: string) => endpoint("/service-types/{serviceTypeId}/files", { serviceTypeId }),
  },
  serviceTypeCategory: {
    serviceTypeCategories: () => endpoint("/service-type-categories"),
    serviceTypeCategory: (serviceTypeCategoryId: string) =>
      endpoint("/service-type-categories/{serviceTypeCategoryId}", { serviceTypeCategoryId }),
  },
  singleSignOn: {
    getUserInfo: () => {
      let port = window.location.port;

      if (port !== "80" && port !== "443" && port !== "") {
        port = ":" + port;
      }

      const url = window.location.protocol + "//" + window.location.hostname + port + "/MyL/redirect_uri?info=json";

      return new URL(url);
    },
  },
  user: {
    currentUser: () => endpoint("/user"),
    nextAccessMessage: () => endpoint("/user/access-message"),
    accessMessage: (messageId: string) => endpoint("/user/access-messages/{messageId}", { messageId }),
  },
  webOrder: {
    createAndOrderWebOrder: () => endpoint("/web-orders/create-and-order"),
    webOrder: (webOrderId: string) => endpoint("/web-orders/{webOrderId}", { webOrderId }),
  },
  webOrderContent: {
    webOrderContents: () => endpoint("/web-order-contents"),
    webOrderContent: (webOrderContentId: string) =>
      endpoint("/web-order-contents/{webOrderContentId}", { webOrderContentId }),
    files: (webOrderContentId: string) =>
      endpoint("/web-order-contents/{webOrderContentId}/files", { webOrderContentId }),
    lines: (webOrderContentId: string) =>
      endpoint("/web-order-contents/{webOrderContentId}/lines", { webOrderContentId }),
  },
  webOrderContentLine: {
    webOrderContentLine: (webOrderContentLineId: string) =>
      endpoint("/web-order-content-lines/{webOrderContentLineId}", { webOrderContentLineId }),
  },
};

const allowRedirectionToAfterLogin = (desiredPage: string) => {
  //Not allowed pages to restrict redirection to after login - only part of url is needed
  const PASSWORD = "/password";

  const DISALLOWED_PARTS_OF_PATH = [PASSWORD];

  //If returned value from findIndex >= 0, a disallowed part of path has been found among the DISALLOWED urls
  const posInDisallowedArray = DISALLOWED_PARTS_OF_PATH.findIndex(element => {
    return desiredPage.indexOf(element) >= 0;
  });

  //Return allowed (true) if no positsion in array was found (-1)
  return posInDisallowedArray === -1;
};

export const myloc = {
  webpageUrl: new URL("https://myloc.se"),
  cookieIntegrityUrl: new URL("https://myloc.se/sv/gdpr/"),
  contactEmail: new URL("info@myloc.se"),
  contactPhone: new URL("040-6304643"),
  supportEmail: new URL("support.logistics@myloc.se"),
  address: {
    street: "Kärleksgatan 1A",
    postalNo: "211 45",
    city: "Malmö",
    county: "Skåne",
  },
};

export const pages = {
  allowRedirectionToAfterLogin,
};
