import { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FETCH_MASTER_DATA_TIME_OUT, REQUEST_STATE, UseFetchRef } from "../../dataTypes";
import { fetchCurrentPageContent } from "../reducers/fetchCurrentPageContent";
import { selectCurrentPageContent } from "../sessionSelectors";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { setError } from "../../dialog/dialogSlice";

let ref: UseFetchRef;

const useFetchCurrentPageContent = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const currentPageContent = useAppSelector(selectCurrentPageContent);

  if ((ref as UseFetchRef | undefined) === undefined)
    ref = {
      fetchedTimestamp: currentPageContent?.fetchedTimestamp ?? 0,
      requestState: currentPageContent?.requestState,
    };

  useEffect(() => {
    if (!currentPageContent) ref = { fetchedTimestamp: 0, requestState: undefined };
    else
      ref = {
        fetchedTimestamp: currentPageContent.fetchedTimestamp,
        requestState: currentPageContent.requestState ?? ref.requestState,
      };
  }, [currentPageContent]);

  const loadPageContent = useCallback(async () => {
    if (result.isError) {
      dispatch(setError({ value: result.errorMessage }));
    } else if (
      ref.requestState !== REQUEST_STATE.PENDING &&
      ref.fetchedTimestamp + FETCH_MASTER_DATA_TIME_OUT < Date.now()
    ) {
      ref = { fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.PENDING };
      await dispatch(fetchCurrentPageContent());
    }
  }, [dispatch, result]);

  return loadPageContent;
};

export default useFetchCurrentPageContent;
