import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { REQUEST_STATE } from "../../dataTypes";
import { ResponseUser } from "../../user/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { CurrentUser, sessionSliceName, SessionState } from "../dataTypes";

async function getCurrentUser(dispatch: AppDispatch) {
  const request = new Request(api.user.currentUser(), Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchCurrentUser = createAsyncThunk<ResponseUser, undefined>(
  sessionSliceName + "/fetchCurrentUser",
  dispatch => getCurrentUser(dispatch),
);

export const addFetchCurrentUserReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(fetchCurrentUser.pending, state => {
    if (!state.currentUser) state.currentUser = { fetchedTimestamp: 0 } as CurrentUser;

    state.currentUser.requestState = REQUEST_STATE.PENDING;
    state.currentUser.errorMessage = undefined;
  });
  builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
    const { payload } = action;

    state.currentUser = { id: payload.id, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED };
  });
  builder.addCase(fetchCurrentUser.rejected, (state, action) => {
    if (!state.currentUser) state.currentUser = {} as CurrentUser;

    state.currentUser.requestState = REQUEST_STATE.REJECTED;
    state.currentUser.errorMessage = action.payload?.message;
  });
};
