import { User } from "../../../../assets/icons";
import colors from "../../../../style/exports.module.scss";
import styles from "./MyPage.module.scss";
import { useTranslate } from "../../../../language/i18n";
import { Link } from "react-router-dom";
import pages from "../../../../utils/pages";
import { useAppSelector } from "../../../../app/hooks";
import { selectSessionId } from "../../../../features/session/sessionSelectors";
import useLazyGetCurrentUser from "../../../../features/session/hooks/useLazyGetCurrentUser";

const MyPageHeader = () => {
  const sessionId = useAppSelector(selectSessionId);
  const { user } = useLazyGetCurrentUser({ loadIfNotInitialized: true });
  const translate = useTranslate();

  const fullName = `${user?.firstName} ${user?.surname}`;

  return (
    <>
      {!!sessionId && (
        <section className={styles.menuWrapper}>
          <Link to={pages.MY_CASES.PATH} className={styles.userButton}>
            <User size="40" color={colors.iconColor} customCssClass={styles.userIcon} />
            <div>
              <div className={styles.title}>{translate(pages.MY_PAGE.NAME)}</div>
              <div>{fullName}</div>
            </div>
          </Link>
        </section>
      )}
    </>
  );
};

export default MyPageHeader;
