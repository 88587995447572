import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import { REQUEST_STATE } from "../../dataTypes";
import { ResponseUserAccessMessage } from "../../userAccessMessage/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { NextUserAccessMessage, sessionSliceName, SessionState } from "../dataTypes";

async function getNextUserAccessMessage(dispatch: AppDispatch) {
  const request = new Request(api.user.nextAccessMessage(), Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchNextUserAccessMessage = createAsyncThunk<ResponseUserAccessMessage | null, undefined>(
  sessionSliceName + "/fetchNextUserAccessMessage",
  dispatch => getNextUserAccessMessage(dispatch),
);

export const addFetchNextUserAccessMessageReducers = (builder: ActionReducerMapBuilder<SessionState>) => {
  builder.addCase(fetchNextUserAccessMessage.pending, state => {
    if (!state.nextUserAccessMessage) state.nextUserAccessMessage = { fetchedTimestamp: 0 } as NextUserAccessMessage;

    state.nextUserAccessMessage.requestState = REQUEST_STATE.PENDING;
    state.nextUserAccessMessage.errorMessage = undefined;
  });
  builder.addCase(fetchNextUserAccessMessage.fulfilled, (state, action) => {
    const { payload } = action;

    if (payload) {
      state.nextUserAccessMessage = {
        id: payload.id,
        fetchedTimestamp: Date.now(),
        requestState: REQUEST_STATE.FULFILLED,
      };
    }
  });
  builder.addCase(fetchNextUserAccessMessage.rejected, (state, action) => {
    if (!state.nextUserAccessMessage) state.nextUserAccessMessage = {} as NextUserAccessMessage;

    state.nextUserAccessMessage.requestState = REQUEST_STATE.REJECTED;
    state.nextUserAccessMessage.errorMessage = action.payload?.message;
  });
};
