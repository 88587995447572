import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import {
  AdditionalPaginationState,
  EntityActionReducerBuilderInterface,
  FulfilledUpdateMethod,
  FULFILLED_UPDATE_METHOD,
  PaginationResponse,
  REQUEST_STATE,
} from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import {
  ResponseServiceTypeCategory,
  ServiceTypeCategoriesFilter,
  ServiceTypeCategory,
  serviceTypeCategorySliceName,
} from "../dataTypes";
import { serviceTypeCategoryAdapter } from "../serviceTypeCategoryAdapter";

async function getServiceTypeCategories(dispatch: AppDispatch, filters?: ServiceTypeCategoriesFilter) {
  const url = api.serviceTypeCategory.serviceTypeCategories();

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchServiceTypeCategories = createAsyncThunk<
  PaginationResponse<ResponseServiceTypeCategory>,
  { filter?: ServiceTypeCategoriesFilter | undefined; fulfilledUpdateMethod?: FulfilledUpdateMethod }
>(serviceTypeCategorySliceName + "/fetchServiceTypeCategories", (dispatch, { filter }) =>
  getServiceTypeCategories(dispatch, filter),
);

export const addFetchServiceTypeCategoriesReducers = (
  builder: EntityActionReducerBuilderInterface<ServiceTypeCategory, AdditionalPaginationState>,
) => {
  builder.addCase(fetchServiceTypeCategories.pending, state => {
    state.requestState = REQUEST_STATE.PENDING;
    state.errorMessage = undefined;
  });
  builder.addCase(fetchServiceTypeCategories.fulfilled, (state, action) => {
    const {
      payload,
      meta: {
        arg: { fulfilledUpdateMethod = FULFILLED_UPDATE_METHOD.UPSERT_MANY },
      },
    } = action;

    const mapResponse = (responseObject: ResponseServiceTypeCategory): ServiceTypeCategory => ({
      ...responseObject,
      fetchedTimestamp: Date.now(),
      requestState: REQUEST_STATE.FULFILLED,
      errorMessage: undefined,
    });

    switch (fulfilledUpdateMethod) {
      case FULFILLED_UPDATE_METHOD.SET_ALL:
        serviceTypeCategoryAdapter.setAll(state, payload.page.map(mapResponse));
        break;
      case FULFILLED_UPDATE_METHOD.UPSERT_MANY:
        serviceTypeCategoryAdapter.upsertMany(state, payload.page.map(mapResponse));
        break;
    }

    state.count = payload.count;
    state.requestState = REQUEST_STATE.FULFILLED;
  });
  builder.addCase(fetchServiceTypeCategories.rejected, (state, action) => {
    state.requestState = REQUEST_STATE.REJECTED;
    state.errorMessage = action.payload?.message;
  });
};
