import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectWebOrderContent } from "../webOrderContentSelectors";
import useFetchWebOrderContent from "./useFetchWebOrderContent";

const useLazyGetWebOrderContent = ({
  id,
  loadIfNotInitialized = false,
}: {
  id?: string;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchWebOrderContent = useFetchWebOrderContent();
  const webOrderContent = useAppSelector(selectWebOrderContent(id));
  const hasInitalized = useRef(false);

  const requestState = webOrderContent?.requestState;
  const errorMessage = webOrderContent?.errorMessage;

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadWebOrderContent = useCallback(async () => {
    await fetchWebOrderContent(id);
  }, [fetchWebOrderContent, id]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [id]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadWebOrderContent();
    }
  }, [isUninitialized, loadWebOrderContent, loadIfNotInitialized]);

  return {
    webOrderContent,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchWebOrderContent: loadWebOrderContent,
  };
};

export default useLazyGetWebOrderContent;
