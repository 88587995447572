import { FormData } from "@myloc/myg-forms";
import { useEffect, useState } from "react";
import { MylocFile } from "../../file/dataTypes";
import { FormDefinition } from "../dataTypes";
import useGetFormDefinition from "./useGetFormDefinition";
import useLazyGetFiles from "./useLazyGetFiles";

const getAggregatedSequences = (
  formData: Readonly<Parameters<typeof FormData>["0"]["form"]["sequences"]>,
  files: { [fileId: string]: MylocFile | undefined },
) => {
  let hasNewData = false;
  const newData = [...formData];

  for (let index = 0; index < newData.length; index++) {
    const sequence = newData[index];

    if ("type" in sequence) {
      if (sequence.fileId && files[sequence.fileId]) {
        if (sequence.file !== files[sequence.fileId]) {
          newData[index] = { ...sequence, file: files[sequence.fileId] };
          hasNewData = true;
        }
      }
    } else if (sequence.sequences) {
      const newSequences = getAggregatedSequences(sequence.sequences, files);

      if (newSequences !== sequence.sequences) {
        newData[index] = { ...sequence, sequences: newSequences };
        hasNewData = true;
      }
    }
  }

  return hasNewData ? newData : (formData as Parameters<typeof FormData>["0"]["form"]["sequences"]);
};

const useGetAggregatedFormDefinition = ({ id, loadFiles }: { id?: string; loadFiles: boolean }) => {
  const { formDefinition, isLoading, refetchFormDefinition } = useGetFormDefinition({ id });
  const { files, isLoading: isFilesLoading } = useLazyGetFiles({ id, loadIfNotInitialized: loadFiles });
  const [modifiedFormDefinition, setModifiedFormDefinition] = useState<
    (Parameters<typeof FormData>["0"]["form"] & Pick<FormDefinition, "definitionVersionId">) | undefined
  >(() =>
    formDefinition && !isLoading
      ? loadFiles
        ? { ...formDefinition, sequences: getAggregatedSequences(formDefinition.sequences, files) }
        : formDefinition
      : undefined,
  );

  useEffect(() => {
    setModifiedFormDefinition(modifiedFormDefinition => {
      const currForm = modifiedFormDefinition ?? (!isLoading ? formDefinition : undefined);

      if (currForm) {
        if (loadFiles) {
          const newSequences = getAggregatedSequences(currForm.sequences, files);

          if (newSequences !== currForm.sequences) return { ...currForm, sequences: newSequences };
        }

        return currForm;
      }
    });
  }, [formDefinition, files, isLoading, loadFiles]);

  return {
    formDefinition: modifiedFormDefinition,
    isLoading,
    isFilesLoading: loadFiles && isFilesLoading,
    refetchFormDefinition,
  };
};

export default useGetAggregatedFormDefinition;
