import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { WebOrderContent, webOrderContentSliceName, ResponseWebOrderContent } from "../dataTypes";
import { webOrderContentAdapter } from "../webOrderContentAdapter";

async function getWebOrderContent(dispatch: AppDispatch, id: string) {
  const url = api.webOrderContent.webOrderContent(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchWebOrderContent = createAsyncThunk<ResponseWebOrderContent, string>(
  webOrderContentSliceName + "/fetchWebOrderContent",
  (dispatch, id) => getWebOrderContent(dispatch, id),
);

export const addFetchWebOrderContentReducers = (builder: EntityActionReducerBuilderInterface<WebOrderContent>) => {
  builder.addCase(fetchWebOrderContent.pending, (state, action) => {
    const id = action.meta.arg;
    const webOrderContent = state.entities[id] ?? ({ id } as WebOrderContent);

    webOrderContentAdapter.upsertOne(state, {
      ...webOrderContent,
      requestState: REQUEST_STATE.PENDING,
      errorMessage: undefined,
    });
  });
  builder.addCase(fetchWebOrderContent.fulfilled, (state, action) => {
    const { payload, meta } = action;
    const id = meta.arg;

    webOrderContentAdapter.updateOne(state, {
      id,
      changes: { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED },
    });
  });
  builder.addCase(fetchWebOrderContent.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload) {
      const id = meta.arg;

      if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) webOrderContentAdapter.removeOne(state, id);
      else
        webOrderContentAdapter.updateOne(state, {
          id,
          changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: payload.message },
        });
    }
  });
};
