import { useCallback, useEffect, useRef } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { selectWebOrderContentLinesById } from "../../webOrderContentLine/webOrderContentLineSelectors";
import { WebOrderContentLinesFilter } from "../dataTypes";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { selectWebOrderContent } from "../webOrderContentSelectors";
import useFetchLines from "./useFetchLines";

const useLazyGetLines = ({
  webOrderContentId,
  filter,
  fulfilledUpdateMethod,
  loadIfNotInitialized = false,
}: {
  webOrderContentId?: string;
  filter?: WebOrderContentLinesFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchLines = useFetchLines();
  const webOrderContent = useAppSelector(selectWebOrderContent(webOrderContentId));
  const hasInitalized = useRef(false);

  const linesObj = webOrderContent?.lines;

  const requestState = linesObj?.requestState;
  const errorMessage = linesObj?.errorMessage;

  const lines = useAppSelector(selectWebOrderContentLinesById(linesObj?.ids), shallowEqual);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadLines = useCallback(async () => {
    await fetchLines({ webOrderContentId, filter, fulfilledUpdateMethod });
  }, [fetchLines, webOrderContentId, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [webOrderContentId, filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;

      loadLines();
    }
  }, [isUninitialized, loadIfNotInitialized, loadLines]);

  return {
    lines,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchLines: loadLines,
  };
};

export default useLazyGetLines;
