import PropTypes from "prop-types";

const Home = ({
  color = "currentColor",
  size = 24,
  customCssClass,
}: {
  color?: string;
  size?: number | string;
  customCssClass?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
  </svg>
);

Home.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customCssClass: PropTypes.string,
};

export default Home;
