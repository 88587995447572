import PropTypes from "prop-types";

import { Form, InputField, Button, THEME } from "@myloc/myloc-gui";
import { useTranslate } from "../../../language/i18n";
import styles from "./RequestResetPassword.module.scss";
import useRequestResetPassword from "../../../features/password/hooks/useRequestResetPassword";
import React, { useEffect, useState } from "react";

function RequestResetPassword({ onClose }: { onClose: () => void }) {
  const translate = useTranslate();
  const [email, setEmail] = useState("");
  const { requestResetPassword, isSuccess } = useRequestResetPassword();

  useEffect(() => {
    if (isSuccess) {
      onClose();
    }
  }, [isSuccess, onClose]);

  const resetPassword = async () => {
    await requestResetPassword({ username: email });
  };

  return (
    <Form onSubmit={resetPassword} className={styles.form}>
      <h2 className={styles.title}>{translate("FORGOT_PASSWORD")}?</h2>
      <p>{translate("FORGOT_PASSWORD_TEXT")}</p>
      <InputField
        type="email"
        label={translate("USERNAME")}
        value={email}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
      />
      <div className={styles.buttons}>
        <Button theme={THEME.PLAIN_PRIMARY} onClick={onClose}>
          {translate("CANCEL")}
        </Button>
        <Button type="submit" customCssClass={styles.submitButton}>
          {translate("RESET")}
        </Button>
      </div>
    </Form>
  );
}

RequestResetPassword.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RequestResetPassword;
