import LogisticsConfirmModal from "../shared/Modals/LogisticsConfirmModal";
import { useTranslate } from "../../language/i18n";
import { useEffect, useRef, useState } from "react";
import { Checkbox } from "@myloc/myloc-gui";
import useUpdateNextUserAccessMessage from "../../features/session/hooks/useUpdateNextUserAccessMessage";

const UserMessageToAcceptModal = () => {
  const { userAccessMessage, updateUserAccessMessage, isSuccess } = useUpdateNextUserAccessMessage();
  const translate = useTranslate();
  const [checked, setChecked] = useState(false);
  const [visible, setVisible] = useState(false);
  const isAccepting = useRef(false);

  useEffect(() => {
    if (isAccepting.current) {
      if (isSuccess) {
        isAccepting.current = false;
        setChecked(false);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (userAccessMessage) {
      const timer = setTimeout(() => setVisible(true), 1000);

      return () => {
        clearTimeout(timer);
      };
    } else setVisible(false);
  }, [userAccessMessage]);

  const submitMessagesAccepted = async () => {
    isAccepting.current = true;
    updateUserAccessMessage({ accept: checked });
  };

  const cancelModal = () => {
    //Do nothing
  };

  if (!userAccessMessage) {
    return null;
  }

  return (
    <LogisticsConfirmModal
      title={userAccessMessage.description}
      text={userAccessMessage.message}
      isHtmlContent={true}
      visible={visible}
      cancel={cancelModal}
      small={false}
      submit={submitMessagesAccepted}
      showDeclineButton={false}
      confirmButtonText={"APPROVE"}
      confirmButtonDisabled={!checked}
      showCloseButton={false}
    >
      <Checkbox
        label={translate("GDPR_CONFIRMATION_MESSAGE")}
        onChange={() => setChecked(checked => !checked)}
        checked={checked}
      />
    </LogisticsConfirmModal>
  );
};

export default UserMessageToAcceptModal;
