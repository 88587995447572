import PropTypes from "prop-types";

const FilterListIcon = ({
  color = "currentColor",
  customCssClass,
}: {
  color?: string;
  size?: number | string;
  customCssClass?: string;
}) => (
  <svg
    viewBox="0 0 24 24"
    fill={color}
    className={customCssClass}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"></path>
  </svg>
);

FilterListIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customCssClass: PropTypes.string,
};

export default FilterListIcon;
