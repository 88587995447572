import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { MylocFile, fileSliceName, ResponseFile } from "../dataTypes";
import { fileAdapter } from "../fileAdapter";

async function getFile(dispatch: AppDispatch, id: string) {
  const url = api.file.file(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchFile = createAsyncThunk<ResponseFile, string>(fileSliceName + "/fetchFile", (dispatch, id) =>
  getFile(dispatch, id),
);

export const addFetchFileReducers = (builder: EntityActionReducerBuilderInterface<MylocFile>) => {
  builder.addCase(fetchFile.pending, (state, action) => {
    const id = action.meta.arg;
    const file = state.entities[id] ?? ({ id } as MylocFile);

    fileAdapter.upsertOne(state, { ...file, requestState: REQUEST_STATE.PENDING, errorMessage: undefined });
  });
  builder.addCase(fetchFile.fulfilled, (state, action) => {
    const { payload, meta } = action;
    const id = meta.arg;

    fileAdapter.updateOne(state, {
      id,
      changes: { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED },
    });
  });
  builder.addCase(fetchFile.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload) {
      const id = meta.arg;

      if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) fileAdapter.removeOne(state, id);
      else
        fileAdapter.updateOne(state, {
          id,
          changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: payload.message },
        });
    }
  });
};
