import { AdditionalRequestState, FetchedIdObject, IdObject } from "../dataTypes";

export const hyperlinkSliceName = "hyperlink";

export interface ResponseHyperlink extends IdObject {
  sequence: number;
  name: string;
  url: string;
}

export interface Hyperlink extends ResponseHyperlink, AdditionalRequestState, FetchedIdObject {}
