import styles from "./ContentWelcome.module.scss";
import FileDownloadList from "../../shared/File/FileDownload/FileDownload";
import { ExternalLinkList, TARGET } from "../../shared/Link";
import Welcome from "./Welcome";
import useLazyGetCurrentPageContent from "../../../features/session/hooks/useLazyGetCurrentPageContent";
import useLazyGetHyperlinks from "../../../features/pageContent/hooks/useLazyGetHyperlinks";
import useLazyGetFiles from "../../../features/pageContent/hooks/useLazyGetFiles";

const ContentWelcome = () => {
  const { pageContent } = useLazyGetCurrentPageContent({ loadIfNotInitialized: true });
  const { hyperlinks } = useLazyGetHyperlinks({ pageContentId: pageContent?.id, loadIfNotInitialized: true });
  const { files } = useLazyGetFiles({ pageContentId: pageContent?.id, loadIfNotInitialized: true });

  return (
    <Welcome title={pageContent?.title ?? ""} text={pageContent?.text}>
      <h2 className={styles.sectionTitle}>{pageContent?.hyperlinkTitle}</h2>
      {!!hyperlinks.length && (
        <ExternalLinkList externalLinks={hyperlinks} target={TARGET.NEW_TAB} customCssClass={styles.fileList} />
      )}

      <h2 className={styles.sectionTitle}>{pageContent?.fileTitle}</h2>
      {!!files.length && <FileDownloadList files={files} />}
    </Welcome>
  );
};

export default ContentWelcome;
