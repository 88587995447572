import { useCallback, useEffect } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectPageContent } from "../../pageContent/pageContentSelectors";
import { selectCurrentPageContent } from "../../session/sessionSelectors";
import useFetchCurrentPageContent from "./useFetchCurrentPageContent";

const useLazyGetCurrentPageContent = ({ loadIfNotInitialized }: { loadIfNotInitialized?: boolean }) => {
  const fetchCurrentPageContent = useFetchCurrentPageContent();

  const currentPageContent = useAppSelector(selectCurrentPageContent);
  const requestState = currentPageContent?.requestState;
  const errorMessage = currentPageContent?.errorMessage;

  const pageContent = useAppSelector(selectPageContent(currentPageContent?.id));

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;
  const isError = requestState === REQUEST_STATE.REJECTED;

  const loadPageContent = useCallback(async () => {
    await fetchCurrentPageContent();
  }, [fetchCurrentPageContent]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized) loadPageContent();
  }, [isUninitialized, loadIfNotInitialized, loadPageContent]);

  return {
    pageContent,
    isUninitialized,
    isLoading,
    isSuccess,
    isError,
    errorMessage,
    fetchPageContent: loadPageContent,
  };
};

export default useLazyGetCurrentPageContent;
