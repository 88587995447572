import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "../CaseAttachments.module.scss";
import { MylocFile } from "../../../../../../features/file/dataTypes";
import { FileDownload } from "../../../../../shared/File/FileDownload/FileDownload";

const Attachment = ({ file }: { file: MylocFile }) => {
  return (
    <li className={styles.card}>
      <div className={styles.row}>
        <div className={classNames(styles.column, styles.icon)}>
          <FileDownload file={file} customCssClass={styles.fileDownload} />
        </div>
      </div>
    </li>
  );
};

Attachment.propTypes = {
  file: PropTypes.object.isRequired,
};

export default Attachment;
