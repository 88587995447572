import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import setQueryFilters from "../../utils/setQueryFilters";
import { EntityActionReducerBuilderInterface, PaginationResponse, REQUEST_STATE } from "../../dataTypes";
import { ResponseFile } from "../../file/dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { PageContent, PageContentFilesFilter, pageContentSliceName } from "../dataTypes";
import { pageContentAdapter } from "../pageContentAdapter";

async function getFiles(dispatch: AppDispatch, pageContentId: string, filters?: PageContentFilesFilter) {
  const url = api.pageContent.files(pageContentId);

  setQueryFilters(url, filters);

  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchFiles = createAsyncThunk<
  PaginationResponse<ResponseFile>,
  { pageContentId: string; filter?: PageContentFilesFilter }
>(pageContentSliceName + "/fetchFiles", (dispatch, { pageContentId, filter }) =>
  getFiles(dispatch, pageContentId, filter),
);

export const addFetchFilesReducers = (builder: EntityActionReducerBuilderInterface<PageContent>) => {
  builder.addCase(fetchFiles.pending, (state, action) => {
    const {
      meta: {
        arg: { pageContentId },
      },
    } = action;

    const pageContent = state.entities[pageContentId] ?? ({ id: pageContentId } as PageContent);

    if (!pageContent.files) pageContent.files = { ids: [] };

    pageContent.files.errorMessage = undefined;
    pageContent.files.requestState = REQUEST_STATE.PENDING;
    pageContentAdapter.upsertOne(state, pageContent);
  });
  builder.addCase(fetchFiles.fulfilled, (state, action) => {
    const {
      meta: {
        arg: { pageContentId },
      },
      payload,
    } = action;

    const pageContent = state.entities[pageContentId];

    if (pageContent) {
      if (pageContent.files) {
        const ids = [...pageContent.files.ids];

        payload.page.forEach(file => {
          if (!ids.includes(file.id)) ids.push(file.id);
        });
        pageContent.files.ids = ids;

        pageContent.files.requestState = REQUEST_STATE.FULFILLED;
      }
    }
  });
  builder.addCase(fetchFiles.rejected, (state, action) => {
    const {
      meta: {
        arg: { pageContentId },
      },
      payload,
    } = action;

    const pageContent = state.entities[pageContentId];

    if (pageContent) {
      if (pageContent.files) {
        pageContent.files.requestState = REQUEST_STATE.REJECTED;
        pageContent.files.errorMessage = payload?.message;
      }
    }
  });
};
