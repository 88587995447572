export const CONTENT_TYPE = {
  SERVICE_TYPE: "SERVICE_TYPE",
} as const;

export const CONTACT_TYPE = {
  PERSON: "PERSON",
  CLIENT: "CLIENT",
} as const;

export const SERVICE_TYPE_CATEGORIES = {
  ORDER: "Beställ",
  ERROR: "Felanmäl",
  ACCESSCARD: "Passerkort",
} as const;
