import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectForm } from "../formSelectors";
import useFetchForm from "./useFetchForm";

const useLazyGetForm = ({ id, loadIfNotInitialized = false }: { id?: string; loadIfNotInitialized?: boolean }) => {
  const fetchForm = useFetchForm();
  const form = useAppSelector(selectForm(id));
  const hasInitalized = useRef(false);

  const requestState = form?.requestState;
  const errorMessage = form?.errorMessage;

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadForm = useCallback(async () => {
    await fetchForm(id);
  }, [fetchForm, id]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [id]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadForm();
    }
  }, [isUninitialized, loadForm, loadIfNotInitialized]);

  return { form, isUninitialized, isLoading, isError, isSuccess, errorMessage, fetchForm: loadForm };
};

export default useLazyGetForm;
