import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAndOrderWebOrder } from "../../webOrder/reducers/createAndOrderWebOrder";
import { WebOrderContent } from "../dataTypes";
import { webOrderContentAdapter } from "../webOrderContentAdapter";

export const addCreateAndOrderWebOrderReducers = (builder: EntityActionReducerBuilderInterface<WebOrderContent>) => {
  builder.addCase(createAndOrderWebOrder.fulfilled, (state, action) => {
    const contents = action.payload.contents;

    if (contents) {
      webOrderContentAdapter.addMany(
        state,
        contents.map(createdContent => ({
          ...createdContent.content,
          fetchedTimestamp: Date.now(),
          files: { ids: createdContent.files?.map(file => file.id) ?? [], requestState: REQUEST_STATE.FULFILLED },
          lines: { ids: createdContent.lines?.map(line => line.id) ?? [], requestState: REQUEST_STATE.FULFILLED },
          requestState: REQUEST_STATE.FULFILLED,
        })),
      );
    }
  });
};
