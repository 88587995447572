import { useCallback, useEffect, useRef } from "react";
import { shallowEqual } from "react-redux";
import { useAppSelector } from "../../../app/hooks";
import { selectFilesById } from "../../file/fileSelectors";
import { WebOrderContentFilesFilter } from "../dataTypes";
import { FulfilledUpdateMethod, REQUEST_STATE } from "../../dataTypes";
import { selectWebOrderContent } from "../webOrderContentSelectors";
import useFetchFiles from "./useFetchFiles";

const useLazyGetFiles = ({
  webOrderContentId,
  filter,
  fulfilledUpdateMethod,
  loadIfNotInitialized = false,
}: {
  webOrderContentId?: string;
  filter?: WebOrderContentFilesFilter;
  fulfilledUpdateMethod?: FulfilledUpdateMethod;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchFiles = useFetchFiles();
  const webOrderContent = useAppSelector(selectWebOrderContent(webOrderContentId));
  const hasInitalized = useRef(false);

  const filesObj = webOrderContent?.files;

  const requestState = filesObj?.requestState;
  const errorMessage = filesObj?.errorMessage;

  const files = useAppSelector(selectFilesById(filesObj?.ids), shallowEqual);

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadFiles = useCallback(async () => {
    await fetchFiles({ webOrderContentId, filter, fulfilledUpdateMethod });
  }, [fetchFiles, webOrderContentId, filter, fulfilledUpdateMethod]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [webOrderContentId, filter]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadFiles();
    }
  }, [isUninitialized, loadIfNotInitialized, loadFiles]);

  return {
    files,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchFiles: loadFiles,
  };
};

export default useLazyGetFiles;
