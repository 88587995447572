import { appDataName } from "../../app/dataTypes";
import { RootState } from "../../app/rootReducer";
import { webOrderSliceName } from "./dataTypes";
import { webOrderAdapter } from "./webOrderAdapter";

const selectSliceState = (state: RootState) => state[appDataName][webOrderSliceName];

const adapterSelectors = webOrderAdapter.getSelectors(selectSliceState);

export const selectErrorMessage = (state: RootState) => selectSliceState(state).errorMessage;

export const selectRequestState = (state: RootState) => selectSliceState(state).requestState;

export const selectWebOrderEntities = adapterSelectors.selectEntities;
export const selectWebOrders = adapterSelectors.selectAll;
export const selectWebOrder = (id = "") => (state: RootState) => adapterSelectors.selectById(state, id);
