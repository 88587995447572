import { createSlice } from "@reduxjs/toolkit";
import { AdditionalRequestState } from "../dataTypes";
import { contactInformationAdapter } from "./contactInformationAdapter";
import { contactInformationSliceName } from "./dataTypes";
import { addFetchContactInformationReducers } from "./reducers/fetchContactInformation";

const slice = createSlice({
  name: contactInformationSliceName,
  initialState: contactInformationAdapter.getInitialState<AdditionalRequestState>({}),
  reducers: {},
  extraReducers: builder => {
    addFetchContactInformationReducers(builder);
  },
});

const { reducer } = slice;

export default reducer;
