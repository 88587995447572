import PropType from "prop-types";
import useLazyGetFiles from "../../../features/serviceType/hooks/useLazyGetFiles";
import { Link, generatePath, useLocation } from "react-router-dom";
import ServiceImage, { IMAGE_PRESET } from "../ServiceImage/ServiceImage";
import styles from "./ServiceCard.module.scss";
import useLazyGetServiceType from "../../../features/serviceType/hooks/useLazyGetServiceType";
import LoadingSpinner from "../../shared/Spinner/LoadingSpinner";
import { useTranslate } from "../../../language/i18n";

function ServiceCard({ id }: { id: string }) {
  const translate = useTranslate();
  const location = useLocation();

  const { serviceType, isLoading: isServiceTypeLoading } = useLazyGetServiceType({
    id,
    loadIfNotInitialized: true,
  });

  const { files, isLoading: isFilesLoading } = useLazyGetFiles({
    serviceTypeId: serviceType?.id,
    filter: { amount: 1 },
    loadIfNotInitialized: true,
  });

  if (isServiceTypeLoading || isFilesLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {serviceType ? (
        <li key={serviceType.id} className={styles.listItem}>
          <Link
            to={{
              pathname: generatePath(`${location.pathname}/:id`, { id }),
              state: { referrer: `${location.pathname}${location.search}` },
            }}
            className={styles.serviceTypeLink}
          >
            <article className={styles.serviceTypeCard}>
              <ServiceImage image={files.length ? files[0] : undefined} preset={IMAGE_PRESET.SMALL} />
              <h3 className={styles.title}>{serviceType.name}</h3>
            </article>
          </Link>
        </li>
      ) : (
        <p>{translate("SERVICE_TYPE_NOT_FOUND")}</p>
      )}
    </div>
  );
}

ServiceCard.propTypes = {
  id: PropType.string.isRequired,
};

export default ServiceCard;
