import PropTypes from "prop-types";
import { useTranslate } from "../../../../language/i18n";
import { SubMenu as SubMenuType } from "../../../../utils/menu/dataTypes";
import { Button, THEME } from "@myloc/myloc-gui";
import styles from "./Menu.module.scss";
import { NavLink } from "react-router-dom";

const SubMenu = ({ subMenu, toggleMenu }: { subMenu: SubMenuType; toggleMenu: () => void }) => {
  const translate = useTranslate();

  return (
    <figure className={styles.subMenu}>
      {subMenu.title && <figcaption className={styles.title}>{translate(subMenu.title)}</figcaption>}
      <ul>
        {subMenu.items.map(item => (
          <li key={item.id} className={styles.link} onClick={toggleMenu}>
            {item.action ? (
              <Button theme={THEME.PLAIN} onClick={item.action}>
                {translate(item.label)}
              </Button>
            ) : (
              <NavLink to={item.link}>{translate(item.label)}</NavLink>
            )}
          </li>
        ))}
      </ul>
    </figure>
  );
};

SubMenu.propTypes = {
  subMenu: PropTypes.object.isRequired,
  toggleMenu: PropTypes.func.isRequired,
};

export default SubMenu;
