import { useCallback, useEffect, useRef } from "react";
import { useAppSelector } from "../../../app/hooks";
import { REQUEST_STATE } from "../../dataTypes";
import { selectUserAccessMessage } from "../userAccessMessageSelectors";
import useFetchUserAccessMessage from "./useFetchUserAccessMessage";

const useLazyGetUserAccessMessage = ({
  id,
  loadIfNotInitialized = false,
}: {
  id?: string;
  loadIfNotInitialized?: boolean;
}) => {
  const fetchUserAccessMessage = useFetchUserAccessMessage();
  const userAccessMessage = useAppSelector(selectUserAccessMessage(id));
  const hasInitalized = useRef(false);

  const requestState = userAccessMessage?.requestState;
  const errorMessage = userAccessMessage?.errorMessage;

  const isUninitialized = requestState === undefined;
  const isLoading = requestState === REQUEST_STATE.PENDING;
  const isError = requestState === REQUEST_STATE.REJECTED;
  const isSuccess = requestState === REQUEST_STATE.FULFILLED;

  const loadUserAccessMessage = useCallback(async () => {
    await fetchUserAccessMessage(id);
  }, [fetchUserAccessMessage, id]);

  useEffect(() => {
    hasInitalized.current = false;
  }, [id]);

  useEffect(() => {
    if (loadIfNotInitialized && isUninitialized && !hasInitalized.current) {
      hasInitalized.current = true;
      loadUserAccessMessage();
    }
  }, [isUninitialized, loadUserAccessMessage, loadIfNotInitialized]);

  return {
    userAccessMessage,
    isUninitialized,
    isLoading,
    isError,
    isSuccess,
    errorMessage,
    fetchUserAccessMessage: loadUserAccessMessage,
  };
};

export default useLazyGetUserAccessMessage;
