import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import { AppDispatch } from "../../../app/store";
import { api } from "../../../config/settings";
import HttpStatusCodes from "../../../utils/HttpStatusCodes";
import { EntityActionReducerBuilderInterface, REQUEST_STATE } from "../../dataTypes";
import { createAsyncThunk } from "../../utils/createAsyncThunk";
import defaultRestOptions from "../../utils/defaultRestOptions";
import { WebOrderContentLine, webOrderContentLineSliceName, ResponseWebOrderContentLine } from "../dataTypes";
import { webOrderContentLineAdapter } from "../webOrderContentLineAdapter";

async function getWebOrderContentLine(dispatch: AppDispatch, id: string) {
  const url = api.webOrderContentLine.webOrderContentLine(id);
  const request = new Request(url, Method.GET);

  return await sendRequest(request, {}, await defaultRestOptions({ dispatch }));
}

export const fetchWebOrderContentLine = createAsyncThunk<ResponseWebOrderContentLine, string>(
  webOrderContentLineSliceName + "/fetchWebOrderContentLine",
  (dispatch, id) => getWebOrderContentLine(dispatch, id),
);

export const addFetchWebOrderContentLineReducers = (
  builder: EntityActionReducerBuilderInterface<WebOrderContentLine>,
) => {
  builder.addCase(fetchWebOrderContentLine.pending, (state, action) => {
    const id = action.meta.arg;
    const webOrderContentLine = state.entities[id] ?? ({ id } as WebOrderContentLine);

    webOrderContentLineAdapter.upsertOne(state, {
      ...webOrderContentLine,
      requestState: REQUEST_STATE.PENDING,
      errorMessage: undefined,
    });
  });
  builder.addCase(fetchWebOrderContentLine.fulfilled, (state, action) => {
    const { payload, meta } = action;
    const id = meta.arg;

    webOrderContentLineAdapter.updateOne(state, {
      id,
      changes: { ...payload, fetchedTimestamp: Date.now(), requestState: REQUEST_STATE.FULFILLED },
    });
  });
  builder.addCase(fetchWebOrderContentLine.rejected, (state, action) => {
    const { payload, meta } = action;

    if (payload) {
      const id = meta.arg;

      if (payload.httpStatusCode === HttpStatusCodes.NOT_FOUND) webOrderContentLineAdapter.removeOne(state, id);
      else
        webOrderContentLineAdapter.updateOne(state, {
          id,
          changes: { requestState: REQUEST_STATE.REJECTED, errorMessage: payload.message },
        });
    }
  });
};
