import { Dictionary } from "@reduxjs/toolkit";
import { useCallback, useEffect } from "react";
import isEqual from "react-fast-compare";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { FulfilledUpdateMethod, RequestState, REQUEST_STATE, UseFetchRef, UseFetchRefs } from "../../dataTypes";
import mergeFetchedTimestampRefAndStateEntities from "../../utils/mergeFetchedTimestampRefAndStateEntities";
import { WebOrderContent, WebOrderContentFilesFilter } from "../dataTypes";
import { fetchFiles } from "../reducers/fetchFiles";
import { selectWebOrderContentEntities } from "../webOrderContentSelectors";
import { useValidateUserIsLoggedIn } from "../../utils/useValidateUserIsLoggedIn";
import { setError } from "../../dialog/dialogSlice";
import cloneDeep from "lodash.clonedeep";

interface Refs extends UseFetchRefs {
  [id: string]: (UseFetchRef & { files: { requestState?: RequestState } }) | undefined;
}

let refs: Refs;
const filterRef: { [webOrderContentId: string]: WebOrderContentFilesFilter | undefined } = {};

const mapWebOrderContents = (webOrderContents: Dictionary<WebOrderContent>) => {
  return Object.entries(webOrderContents).reduce((result, [id, webOrderContent]) => {
    result[id] = webOrderContent
      ? {
          files: { requestState: webOrderContent.files?.requestState },
          fetchedTimestamp: webOrderContent.fetchedTimestamp,
          requestState: webOrderContent.requestState,
        }
      : undefined;

    return result;
  }, {} as Refs);
};

const useFetchFiles = () => {
  const dispatch = useAppDispatch();
  const result = useValidateUserIsLoggedIn();

  const webOrderContents = useAppSelector(selectWebOrderContentEntities);

  if ((refs as UseFetchRefs | undefined) === undefined) refs = mapWebOrderContents(webOrderContents);

  useEffect(() => {
    refs = mergeFetchedTimestampRefAndStateEntities(refs, mapWebOrderContents(webOrderContents));
  }, [webOrderContents]);

  const loadFiles = useCallback(
    async ({
      webOrderContentId,
      filter,
      fulfilledUpdateMethod,
    }: {
      webOrderContentId?: string;
      filter?: WebOrderContentFilesFilter;
      fulfilledUpdateMethod?: FulfilledUpdateMethod;
    }) => {
      if (result.isError) {
        dispatch(setError({ value: result.errorMessage }));
      } else {
        if (webOrderContentId) {
          const ref = refs[webOrderContentId];

          if (!isEqual(filter, filterRef[webOrderContentId]) || ref?.files.requestState !== REQUEST_STATE.PENDING) {
            refs[webOrderContentId] = {
              files: { requestState: REQUEST_STATE.PENDING },
              fetchedTimestamp: Date.now(),
              requestState: REQUEST_STATE.PENDING,
            };
            filterRef[webOrderContentId] = cloneDeep(filter);
            await dispatch(fetchFiles({ webOrderContentId, filter, fulfilledUpdateMethod }));
          }
        }
      }
    },
    [dispatch, result],
  );

  return loadFiles;
};

export default useFetchFiles;
