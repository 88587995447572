import { Provider } from "react-redux";
import { ErrorBoundary } from "@myloc/myloc-utils";
import Site from "./components/Site/Site";
import store from "./app/store";

const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <Site />
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
