import { FiltrationFilter, PaginationFilter, SortFilter } from "../utils/pagination";
import { ValueOf } from "../../utils/dataTypes";
import { AdditionalRequestState, FetchedIdObject, IdObject, LabelValue, ReferenceIds } from "../dataTypes";
import { ResponseFile } from "../file/dataTypes";
import { CreateFormRequest, ResponseForm } from "../form/dataTypes";
import { CreateWebOrderContentLineRequest, ResponseWebOrderContentLine } from "../webOrderContentLine/dataTypes";

export const webOrderContentSliceName = "webOrderContent";

export const CONTENT_TYPE = {
  FUNCTION: "FUNCTION",
  FITTING: "FITTING",
  PICKUP: "PICKUP",
  WORK: "WORK",
  PRODUCT_PICKUP: "PRODUCT_PICKUP",
  PRODUCT_DELIVERY: "PRODUCT_DELIVERY",
  NEW_PRODUCT: "NEW_PRODUCT",
  DIRECT_PRESCRIPTION: "DIRECT_PRESCRIPTION",
  SERVICE_TYPE: "SERVICE_TYPE",
} as const;

interface ContentType extends LabelValue {
  value: ValueOf<typeof CONTENT_TYPE>;
}

export const SUB_CONTENT_TYPE = {
  NONE: "NONE",
  REPARATION: "REPARATION",
  ADJUSTMENT: "ADJUSTMENT",
  INSTALLATION: "INSTALLATION",
  ASSEMBLY: "ASSEMBLY",
  SPECIAL_ADJUSTMENT: "SPECIALADJUSTMENT",
} as const;

interface SubContentType extends LabelValue {
  value: ValueOf<typeof SUB_CONTENT_TYPE>;
}

export const PRIORITY = {
  HIGH: "HIGH",
  NORMAL: "NORMAL",
  LOW: "LOW",
} as const;

interface Priority extends LabelValue {
  value: ValueOf<typeof PRIORITY>;
}

export const STATUS = {
  DRAFT: 0,
  READY_TO_ORDER: 10,
  ORDERED: 20,
  IN_PROCESS: 80,
  COMPLETED: 90,
  CANCELLED: 99,
} as const;

interface Status extends LabelValue {
  value: ValueOf<typeof STATUS>;
}

export const EXTERNAL_STATUS = {
  ORDERED_FROM_SUPPLIER: 10,
  WAITING_FOR_DELIVERY: 20,
  DELIVERED_TO_BASE_STORAGE: 30,
  DELIVERED_TO_CLIENT: 31,
} as const;

interface ExternalStatus extends LabelValue {
  value: ValueOf<typeof EXTERNAL_STATUS>;
}

export interface ResponseWebOrderContent extends IdObject {
  webOrderId: string;
  contentNumber: string;
  contentType: ContentType;
  subContentType: SubContentType;
  priority: Priority;
  status: Status;
  externalStatus?: ExternalStatus;
  externalStatusComment?: string;
  contactInformationId: string;
  formId?: string;
}

export interface WebOrderContent extends ResponseWebOrderContent, AdditionalRequestState, FetchedIdObject {
  files?: ReferenceIds;
  lines?: ReferenceIds;
}

export interface CreateWebOrderContentResponse {
  content: ResponseWebOrderContent;
  lines?: ResponseWebOrderContentLine[];
  form?: ResponseForm;
  files?: ResponseFile[];
}

interface WebOrderContentSorting extends SortFilter {
  id: "orderDate";
}

interface WebOrderContentStatusFilter extends FiltrationFilter {
  id: ValueOf<typeof STATUS>;
}

export interface WebOrderContentsFilter extends PaginationFilter {
  freeTextSearch?: string;
  fromOrderDate?: string;
  toOrderDate?: string;
  responsibleId?: string;
  status?: WebOrderContentStatusFilter[];
  sort?: WebOrderContentSorting[];
}

export interface WebOrderContentFilesFilter extends Omit<PaginationFilter, "sort"> {}

export interface WebOrderContentLinesFilter extends Omit<PaginationFilter, "sort"> {}

export interface CreateWebOrderContentRequest {
  contentType: ValueOf<typeof CONTENT_TYPE>;
  subContentType: ValueOf<typeof SUB_CONTENT_TYPE>;
  priority: ValueOf<typeof PRIORITY>;
  form?: CreateFormRequest;
  lines?: CreateWebOrderContentLineRequest[];
}
